
.uye_cerceve{
  border: 2px solid #A4320A;
  border-radius: 1rem;
  height: 12rem;
}
.uye_card{
  width: 10rem;
  height: 10rem;
  margin-left: 1rem;
  border: 1px #34009C solid;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  float:left;
}
.uye_bilgi{
  float: left;
  padding-left: 2rem;
  margin-top: 1rem;
  
}
.uye_ad{
  font-size: medium;
  font-weight: bold;
}
.uye_gorev{
  font-size: small;
}



/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 994px) {
   
    .uye_card{
      width: 8rem !important;
      height: 8rem !important;

    }
    
}

