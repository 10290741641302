
/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
    
    
    
    .invest{
        margin-top: -1rem !important;
    }
    
 
 }