  /* On screens that are less than 700px wide, make the sidebar into a topbar */
  @media screen and (max-width: 700px) {
    .belgeholder{
       
       margin-bottom: 1rem !important;

      
    
    }
    .sertifikaimg{
        width:100% !important;
        height: auto !important;
        padding: 0% !important;
        margin-bottom: 0.5rem !important;
        display: block !important;
        justify-content: center !important;
    }
    .alındı{
        font-size: 10px !important;
    }
    .tanıtım{
        font-size: 10px !important;
        padding-right: 2rem !important;
    }
    .alındıbaslık{
        font-size: 10px !important;
    }
}