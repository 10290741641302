
  .blog textarea {
    background-color: #333;
    color: black;
  }
  
  .blog-pop {
    background: linear-gradient(45deg, #49a09d, #5f2c82);
    height: 100%;
    overflow-y: scroll;
  }
  
  label{
    color: black;
  }