.navbar-nav li{
    list-style-type:none;
    padding-left: 2rem;
 
}
.nav-link{
  color:white;
}
.nav-link:hover{
  color:#222222 !important;
}


.menu-item-link{
  text-decoration: none !important;
  font-size: medium;
  color:white !important;
  
}
.menu-item-link:hover{
  background-color: #A4203A !important;
  
}
.dropdown-toggle{
  font-size: medium;
  text-decoration: none !important;
  color:white !important;
  
}
.dropdown-toggle:hover{
  color: #222222 !important;
  
}
.dropdown-menu{
  background-color: #222222 !important;
}


/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 994px) {
  .row{
    justify-content: flex-end !important;
  }
  .navbar-toggler{
    float:right !important;
   
  }
}

