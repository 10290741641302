
.ana1{
    color:white;
    font-size: large;
    float: left;
}

.ana1:hover{
    color:black;
    text-decoration:none;
}

.subtitle{
    
    font-size: large;
    color:white;
    float:left;
}


.title{
    float:left;
    font-size:large !important;
    color:#A4203A;
    
}
.gri{
    
    background-color: #F2F2F2;
    padding-top: 2rem;
    padding-left: 5rem;
    height: 8rem;
    

}

/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {

    
    .title{
        font-size: medium !important;
    
    }
    
}