/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
    
    .sertifikaimg{
        width:100% !important;
        height: auto !important;
        padding: 0% !important;
        margin-bottom: 0.5rem !important;
        display: block !important;
        justify-content: center !important;
    }
    
}