.card{
    padding-bottom: 1%;
  }
  .card:hover{
     
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
  
          opacity: 1;
          transition: .5s ease;
          backface-visibility: hidden;
         
        
  }
  
  .Ih_title{
    
    color:black;
    font-size: x-large;
  }
  .Ih_title:hover{
    text-decoration: none;
    color:#A4203A;
  }
  
  /* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
   .investmentholder{
      height:30rem !important;
      margin-bottom: 1rem !important;
   }
      
   
  .card{
    height:15rem !important;
  

  }
  .Ih_title{
    font-size: large !important;

  }
  

}