/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
  
    .girisresmi{
        width:100% !important;
        height:20rem !important;

       
    }
    .invest{
        margin-top: -1rem !important;
    }

    .harita{
      height:30rem !important;
      margin-bottom: 1rem !important;

    }
    .yandexharita{
      height:30rem !important;
      
    }
    
 
 }